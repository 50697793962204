import { DropdownMenuMenuItem } from '~/core/ui/DropdownMenu'
import { JOB_ACTION, JOB_STATUS_ENUM } from './enum'
import { ISelectOption } from '~/core/ui/Select'
import { TFunction } from 'next-i18next'

export const jobConvertSalary = (
  labelValue: number | string
): number | string => {
  let value = Number(labelValue).toString()
  return value.replace(/(?<!\.\d*)(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
}

export const renderCombineText = (listCombineText: string[]) => {
  return listCombineText.filter((item) => !!item).join(', ')
}

export const descriptionLocationSelectJob = (
  jobLocations: Array<{
    state?: string
    country?: string
  }>,
  t: TFunction
) => {
  return jobLocations?.length > 0
    ? jobLocations?.length === 1
      ? `${jobLocations?.[0]?.state}, ${jobLocations?.[0]?.country}`
      : jobLocations?.length > 1
      ? `${jobLocations?.length} ${t('label:locations')}`
      : ''
    : ''
}

export const jobActionMap = {
  publish: {
    actions: [
      JOB_ACTION.VIEW,
      JOB_ACTION.EDIT,
      JOB_ACTION.CHANGE_STATUS,
      JOB_ACTION.DUPLICATE_JOB,
      JOB_ACTION.SHARE_JOB,
      JOB_ACTION.DELETE
    ],
    allowChangeStatus: [
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.archived,
      JOB_STATUS_ENUM.publish
    ]
  },
  internal: {
    actions: [
      JOB_ACTION.VIEW,
      JOB_ACTION.EDIT,
      JOB_ACTION.CHANGE_STATUS,
      JOB_ACTION.DUPLICATE_JOB,
      JOB_ACTION.SHARE_JOB,
      JOB_ACTION.DELETE
    ],
    allowChangeStatus: [
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.archived,
      JOB_STATUS_ENUM.internal
    ]
  },
  archived: {
    actions: [
      JOB_ACTION.VIEW,
      JOB_ACTION.CHANGE_STATUS,
      JOB_ACTION.DUPLICATE_JOB,
      JOB_ACTION.DELETE
    ],
    allowChangeStatus: [
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.archived
    ]
  },
  draft: {
    actions: [JOB_ACTION.DELETE, JOB_ACTION.DUPLICATE_JOB, JOB_ACTION.EDIT],
    allowChangeStatus: [
      JOB_STATUS_ENUM.internal,
      JOB_STATUS_ENUM.publish,
      JOB_STATUS_ENUM.draft
    ]
  }
}

export const authorizingJobActionMenu = (
  jobStatus: JOB_STATUS_ENUM,
  menuItems: (DropdownMenuMenuItem & { actionID: JOB_ACTION })[]
) => {
  return menuItems.filter((item) =>
    (jobActionMap[jobStatus].actions || []).includes(item.actionID)
  )
}

export const authorizingJobStageChange = (
  jobStatus: JOB_STATUS_ENUM,
  menuItems: ISelectOption[]
) => {
  return menuItems.filter((item) =>
    (jobActionMap[jobStatus].allowChangeStatus || []).includes(
      item.value as JOB_STATUS_ENUM
    )
  )
}
